@import "../css-vars.css";

.LeftNavContainer {

    background: var( --crimson-blue-dark, #e6dce5);
    padding: 12px 0;
    height: 100%;
}



.MenuContainer {
    border-top: 1px solid var(--crimson-gray);
    display: flex;
    align-items: center;
    height: 36px;
    flex-direction: row;


    a {
        display: flex;
        /* flex-direction: row; */
        text-decoration: none;
        flex: 1 100%;
        color: black;
        font-size: 14px;
        height: 100%;
        align-items: center;
        padding: 0 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: white;
        &:hover {
            background-color: var(--crimson-blue-light);
        }

        svg {
            width: 22px;
            height: 22px;
            margin-right: 8px;
            fill: white;
            
        }

        >div {
            display: flex;
            align-items: center;
        }
        &.SideMenuActive {
            background-color: var(--crimson-blue-light);
        }


   

 
    }


}

