.item {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    --az: calc(var(--i)*1turn/var(--n));
    transform: rotate(var(--az)) translate(4em) rotate(calc(-1*var(--az)));
    background: silver;
    font: 2em/2 trebuchet ms, sans-serif;
    text-align: center;
    counter-reset: i var(--i);
  }
  .item::before {
    content: counter(i);
  }
  .circle {
    --n: 12;
  }

  .transparent_square{
    width: 100%;
    height: auto;
  }
  
  .central_text{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  
  .moon_container{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;

    transform: translate(-50%, -50%);
  }
  
  .moon{
    width: 100%;
    height: auto;
    position: relative;
    left: 130%;
    opacity: 1;
  
  }
  .container-fluid{
    padding-top: 50px !important;
    padding-bottom: 80px !important;

  
  }
  .moon_container.moon2{
    transform: translate(-50%, -50%) rotate(60deg);
    animation: moon_container_2 3s linear;
  }
  
  .moon:hover{
   opacity: .1;
        background-color: blue;
  }
  
  
  .moon_container.moon3{
    transform: translate(-50%, -50%) rotate(120deg);
    animation: moon_container_3 3s linear;
  }
  .moon_container.moon4{
    transform: translate(-50%, -50%) rotate(180deg);
    animation: moon_container_4 3s linear;
  }
  .moon_container.moon5{
    transform: translate(-50%, -50%) rotate(240deg);
    animation: moon_container_5 3s linear;
  }
  .moon_container.moon6{
    transform: translate(-50%, -50%) rotate(300deg);
    animation: moon_container_6 3s linear;
  }
  
  .moon.moon1{
    animation: moon_1 3s linear;
  }
  .moon.moon2{
    transform: rotate(-60deg);
    animation: moon_2 73s linear;
  }
  .moon.moon3{
    transform: rotate(-120deg);
    animation: moon_3 3s linear;
  }
  .moon.moon4{
    transform: rotate(-180deg);
    animation: moon_4 3s linear;
  }
  .moon.moon5{
    transform: rotate(-240deg);
    animation: moon_5 3s linear;
  }
  .moon.moon6{
    transform: rotate(-300deg);
    
    animation: moon_6 3s linear;
  }