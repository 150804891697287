
/* @import url("./css-vars.css"); */
@import "../css-vars.css";
.header{
    background-color: var(--crimson-red);
    display: flex;
    position: relative;
    height: 32px;
    margin-bottom: 14px;
}

.avatar {
    position: absolute;
    border: 2px solid white;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
    margin: 8px;
    background: var(-crimson-gray);
    
}

.checkIconContainer {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 300;
    display: flex;
    display: flex;
    align-items: center;
    div:first-child {
    color: white;
    font-size: 12px;
}
}

.checkIconContainer:after{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    /* border-color: transparent #e2b40f transparent transparent; */
    border-color: transparent;
    right: 0;
    top: 0;
    position: absolute;
    z-index: -1;
}

.checked {
    color: white;
    margin-left: 4px;
}

