/* @import "../../css-vars.css"; */

.YourEvent {
  height: 100%;
  width: 100%;
  background: var(--crimson-light-gray);

}
.bannerCard {

background-color: rgb(255, 255, 255);
background-image: linear-gradient(rgb(255, 255, 255) 10%, rgb(242, 229, 229) 90% );
/* background-position: right bottom;
background-size: 330px;
background-repeat: no-repeat;
background: url(crimsonflower.png); */
}
.flowerImage {
  width: 200px;
  position: absolute;
  bottom: -20px;
  right: -12px;
  
  @media screen and (min-width: 750px) {
    width: 240px;
    transition: width .5s;
  }
  @media screen and (min-width: 1050px) {
    width: 320px;
    transition: width .5s;
  }

}