.top {
    height: 120px;
    background-color: var(--crimson-light-gray);

    position: relative;

    .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 120px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 1.25rem;
        line-height: 1;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        bottom: -20px;
        margin-left: auto;
        margin-right: auto;

        img {
            width: 100%;
            height: 100%;
            text-align: center;
            object-fit: cover;
            color: transparent;
            text-indent: 10000px;
        }

    }
}

input {
    font-size: 12px;
    color: red;
}

.profileInfoContainer {
    /* border:1px solid blue; */

}

.profileInfo {
    /* border: 1px solid blue; */
    display: flex;
    flex-flow: row;
    padding: 8px;

    >div {
        font-size: 14px;
        width: 50%;
        font-weight: 400;
        text-align: left;


        &:first-child {
            text-align: right;
            padding-right: 6px;
            font-style: oblique;
            font-weight: 600;
        }

    }

    .notProvided {
        font-style: oblique;
        color: gray;
    }
}

.editProfileContainer {
    font-size: 16px;
    border: 1px solid blue;
}

