:root {
  --drawer-width: 200px;
  --app-bar-height: 120px;
  --theme-color: #761e59;
  --theme-color-secondary: #ebdae6;
  --sidenav-background: #e6dce5;

  --crimson-red: #990000;
  --crimson-red-dark: #7b0000;
  --crimson-red-light: #ffaabb;
  --crimson-blue-dark: #325972;
  --crimson-blue-light: #6d97b6;
  --crimson-light-gray: #f0eadf;
  --crimson-gray: #aaaaaa;
  --mytest: purple;


 }

